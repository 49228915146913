import 'public/js/sweetalert';
import 'public/style.scss';
import Cookies from 'js-cookie';
import 'public/main';
import 'core-js';
import Rails from "@rails/ujs";
Rails.start();

window.jQuery = $;
window.$ = $;
window.Swal = Swal;
window.Cookies = Cookies;
