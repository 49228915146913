import WOW from 'wow.js'
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
// import 'photoswipe/style.css';
import Swiper, { Navigation, Autoplay, EffectFade, Pagination } from 'swiper';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';

Swiper.use([Navigation, Autoplay, EffectFade, Pagination]);

// JavaScript
$(document).ready( function () {
  $(".header-menu-btn").on("click", function () {
    var id = $(this).data('target');
    $(this).toggleClass('is-active');
    $(id).toggleClass('is-active');
  });

  $(".header-menu-item.dropdown").on("click", function () {
    $(this).toggleClass('is-active');
  });

  $('.header-menu-nav').find('a').on('click', function () {
    $('#mobileMenuBtn').toggleClass('is-active');
    $('.header-menu').removeClass('is-active');
  });

  var header_type = $('.frame').data('header-type');
  if (header_type == 'logo_black') {
    $('.header-logo').addClass('is-black');
  } else if (header_type == 'black') {
    $('.header-logo').addClass('is-black all-block');
    $('.header-menu').addClass('is-black');
  }

  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 3600,
    },
    speed: 2000,
    loop: true,
    pagination: {
      el: "#indexPagintaion",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + ' banner-nav"></span>';
      },
    },
  });

  $(window).on('scroll',function(){
  // transparent header
    var scroll = $(window).scrollTop();
    if(scroll > 100){
      $('.header').addClass("rwd-header");
    }else{
      $('.header').removeClass("rwd-header");
    }
  })

  new WOW().init();

  const lightbox = new PhotoSwipeLightbox({
    gallery: '#project-gallery',
    children: 'a',
    pswpModule: () => import('photoswipe'),
    padding: { top: 20, bottom: 60, left: 10, right: 10 },
    maxWidthToAnimate: 1500,
  });
  // const lightbox = new PhotoSwipeLightbox(options);
  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: '',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            } else {
              // get caption from alt attribute
              captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
            }
          }
          el.innerHTML = captionHTML || '';
        });
      }
    });
  });
  lightbox.init();

  // sidebar
  var windowWidth = $(window).width();

  if (windowWidth <= 900 && $('.sidebar-nav').length >= 1) {
    var item = $('.sidebar-item.is-active').width();
    if ($('.sidebar-item').hasClass('is-active')) {
      var item_left_size = $('.sidebar-item.is-active').offset().left;
      if (item_left_size > windowWidth / 2 - item / 2) {
        var scrollTo = item_left_size - windowWidth / 2 + item / 2;
        $('.sidebar-items').scrollLeft(scrollTo);
      };
    }
  }

  // ckeditor
  $(".custom-editor").find('iframe').wrap('<div class="iframe-outer"></div>');
  $(".custom-editor").find('table').wrap('<div class="table-responsive"></div>');
} );

