import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';

const Swal_popup = Swal.mixin({
  width: 800,
  showCloseButton: true,
  showConfirmButton: false,
  backdrop: '',
  customClass: {
    popup: 'popup-hint',
    htmlContainer: 'html-hint',
  },
});

window.Swal = Swal;
window.Swal_popup = Swal_popup;
